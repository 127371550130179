export const LOGOUT = 'LOGOUT';
export const SELECT_MARKETINGBRAND = 'SELECT_MARKETINGBRAND';

export const UPDATE_ENTITY_START = 'UPDATE_ENTITY_START';
export const UPDATE_ENTITY_SUCCESS = 'UPDATE_ENTITY_SUCCESS';
export const UPDATE_ENTITY_ERROR = 'UPDATE_ENTITY_ERROR';

export const CURRENTUSER_SETTING_START = 'CURRENTUSER_SETTING_START';
export const CURRENTUSER_SETTING_SUCCESS = 'CURRENTUSER_SETTING_SUCCESS';
export const CURRENTUSER_SETTING_ERROR = 'CURRENTUSER_SETTING_ERROR';

export const GET_CURRENTUSER_START = 'GET_CURRENTUSER_START';
export const GET_CURRENTUSER_SUCCESS = 'GET_CURRENTUSER_SUCCESS';
export const GET_CURRENTUSER_ERROR = 'GET_CURRENTUSER_ERROR';

export const GET_COLLECTION_START = 'GET_COLLECTION_START';
export const GET_COLLECTION_SUCCESS = 'GET_COLLECTION_SUCCESS';
export const GET_COLLECTION_ERROR = 'GET_COLLECTION_ERROR';

export const UPDATE_ITEM_IN_STATE_COLLECTION_START = 'UPDATE_ITEM_IN_STATE_COLLECTION_START';
export const UPDATE_ITEM_IN_STATE_COLLECTION_SUCCESS = 'UPDATE_ITEM_IN_STATE_COLLECTION_SUCCESS';
export const UPDATE_ITEM_IN_STATE_COLLECTION_ERROR = 'UPDATE_ITEM_IN_STATE_COLLECTION_ERROR';

export const DELETE_ITEM_FROM_STATE_COLLECTION = 'DELETE_ITEM_FROM_STATE_COLLECTION';

let domain = "https://toccstyles.originalcottages.co.uk/";
if (window.location.hostname === 'localhost') {
  domain = '/css/';
} 
export const CDN = domain + require('toccstyles').version;